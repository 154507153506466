import React from 'react'
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet'

import Card from '../components/card'
import Footer from '../components/footer'
import NavBar from '../components/navbar'
import './home.css'

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const platformUrl = process.env.REACT_APP_PLATFORM_URL || "platform.commerbiz.com";

  return (
    <div className="home-container">
      <Helmet>
        <title>CommerBiz 最低0元建立電商</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta property="og:title" content="CommerBiz 最低0元建立電商" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.commerbiz.com/og-image.png" />
        <meta property="og:description" content="依流量收費，每月最低0元，輕鬆開店" />
      </Helmet>
      <NavBar></NavBar>
      <header className="home-hero">
        <div className="home-content">
          <h1 className="home-title">{t('hero.title')}</h1>
          <span className="home-description">
            {t('hero.minimum_fee')} <br></br>
            {t('hero.pay_for_visit')} <br></br>
            {t('hero.flexible_pricing')}
          </span>
          <img alt="image" src="/line-300w.png" className="home-image" />
        </div>
        <div className="home-image01">
          <img
            alt="image"
            src="/e-commerce3.png"
            className="home-image02"
          />
        </div>
      </header>
      <section className="home-mission">
        <h2 className="home-text08">
          {t('join.title')} <br></br>
          <span style={{ fontSize: 18 }}>
            {t('join.0%_order_fee')} <br></br>
            {t('join.keep_all_profits')}
          </span>
        </h2>
        <a href={`https://${platformUrl}/admin/signup`}>
          <button className="button home-button06">
            <span className="home-text09">{t('join.create_account')}</span>
          </button>
        </a>
      </section>
      <section className="home-improve" id='contact'>
        <div className="home-tabs">
          <div className="home-content1">
            <div className="home-details">
              <div className="home-header">
                <h2 className="home-text12">{t('customised_service.title1')} <br></br>{t('customised_service.title2')}</h2>
                <div className="home-description1">
                  <p className="home-text13">
                    {t('customised_service.response_policy')}
                  </p>
                  <p className="home-text14">
                    {t('customised_service.reach_out')}
                  </p>
                </div>
              </div>
              <button className="button" onClick={() => { window.Tawk_API.maximize(); }}>
                <span className="home-text15">
                  <span>{t('customised_service.inquiry')}</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-image04">
              <img
                alt="image"
                src="/e-commerce5.png"
                className="home-image05"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-pricing" id='pricing'>
        <h2 className="home-text18">方案費用</h2>
        <p>提供最彈性的費用是我們的承諾，從入門方案到企業方案，為您量身打造全新的電商體驗</p>

        <div className="pricing-table">
          <div className="pricing-card">
            <h2 style={{ background: 'rgb(255 236 163)' }}>入門方案</h2>
            <p className="price">最低 <b style={{ fontSize: 18 }}>0 元</b>/月</p>
            <p>· 72 小時回覆</p>
            <p>· 頁面編輯器</p>
            <p>· 金流、物流串接</p>
            <p>· 商品訂單 0 抽成</p>
            <p>· 會員數量無上限</p>
            <p>· 圖片儲存無上限</p>
            <p>· commerbiz.com 免費子網域</p>
          </div>

          <div className="pricing-card design-dev">
            <h2 style={{ background: 'rgb(255 227 120)', position: 'relative' }}>
              <div style={{ position: 'absolute', fontSize: 12, top: -11, backgroundColor: '#fc91ed', color: 'white', padding: '5px 10px', borderRadius: 12 }}>推薦</div>
              進階方案
            </h2>
            <p className="price"><b style={{ fontSize: 18 }}>10,000 ~ 40,000 元</b>/次</p>
            <p>· 24 小時回覆保證</p>
            <p>· 專人製作客製化版面</p>
            <p>· 客製化金流、物流串接</p>
            <p>· 客製化會員系統</p>
            <p>· 客製網域</p>
            <p>· 入門方案所有條件</p>
          </div>

          <div className="pricing-card webflow-dev">
            <h2 style={{ background: 'rgb(255 215 64)' }}>企業方案</h2>
            <p className="price"><b style={{ fontSize: 18 }}>+150,000 元</b>/專案</p>
            <p>· 8 小時回覆保證</p>
            <p>· 私有資料庫建置</p>
            <p>· 私有 AWS 雲端部署</p>
            <p>· 技術支援團隊</p>
            <p>· 服務穩定性代為監控</p>
          </div>

          <div className="pricing-card refer">
            <h2>推薦計畫</h2>
            <p>推薦 CommerBiz 給好友，領取 3% 回饋表達</p>
            <p style={{lineHeight: 2}}>被推薦人訂購 <span style={{ fontSize: 12, backgroundColor: 'rgb(255 227 120)', color: 'gray', padding: '5px 10px', borderRadius: 12, marginRight: 4 }}>進階方案</span>即符合資格，3% 為訂購總額</p>
          </div>
        </div>
      </section>
      <section className="home-services" id="feature">
        <h2 className="home-text18">{t('feature.title')}</h2>
        <div className="home-cards">
          <div className="home-row">
            <Card
              Icon="/fee-icon.png"
              Title={t('feature.minimum_fee.title')}
              Description={t('feature.minimum_fee.description')}
            ></Card>
            <Card
              Icon="/service-charge-icon.png"
              Title={t('feature.order_fee.title')}
              Description={t('feature.order_fee.description')}
            ></Card>
          </div>
          <div className="home-row1">
            <Card
              Icon="/custom-service-icon.png"
              Title={t('feature.customised_service.title')}
              Description={t('feature.customised_service.description')}
            ></Card>
            <Card
              Icon="/store-icon.png"
              Title={t('feature.storefront_editor.title')}
              Description={t('feature.storefront_editor.description')}
            ></Card>
          </div>
        </div>
      </section>
      {/* Block for partners logo */}
      {/* <section className="home-partners">
        <div className="home-content3">
          <h2 className="home-text33">Our partners</h2>
          <p className="home-text34">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
        </div>
        <div className="home-partners-desktop">
          <div className="home-column">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B2%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column01">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202107.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202110.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column02">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202111.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B5%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column03">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B2%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202110.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202107.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
        </div>
        <div className="home-partners-mobile">
          <div className="home-row2">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202111.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B5%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column04">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202107.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column05">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202111.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202110.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column06">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B2%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* Block for case study */}
      {/* <section className="home-services1">
        <div className="home-heading1">
          <h2 className="home-text35">Take a look at the latest articles</h2>
          <p className="home-text36">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
        </div>
        <div className="home-sections">
          <div className="home-row3">
            <div className="home-section">
              <div className="home-header1">
                <h3 className="home-text37">Understand your customers</h3>
                <div className="home-description2">
                  <p className="home-text38">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text39">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-section1">
              <div className="home-header2">
                <h3 className="home-text43">Own your business</h3>
                <div className="home-description3">
                  <p className="home-text44">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text45">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
          </div>
          <div className="home-row4">
            <div className="home-section2">
              <div className="home-header3">
                <h3 className="home-text49">How to build your team</h3>
                <div className="home-description4">
                  <p className="home-text50">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text51">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-section3">
              <div className="home-header4">
                <h3 className="home-text55">We can help your business</h3>
                <div className="home-description5">
                  <p className="home-text56">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text57">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="home-galleries">
        <div className="home-gallery-desktop">
          <div className="home-column07">
            <img
              alt="image"
              src="/pexels-darina-belonogova-7959670%201-1300w.png"
              className="home-image43"
            />
          </div>
          <div className="home-column08">
            <div className="home-row5">
              <div className="home-yellow"></div>
              <img
                alt="image"
                src="/pexels-darina-belonogova-8373875%203-1300w.png"
                className="home-image44"
              />
            </div>
            <div className="home-row6">
              <img
                alt="image"
                src="/pexels-darina-belonogova-7964869%201-500w.png"
                className="home-image45"
              />
              <img
                alt="image"
                src="/group%202106.svg"
                className="home-image46"
              />
            </div>
          </div>
        </div>
        <div className="home-gallery-mobile">
          <div className="home-column09">
            <img
              alt="image"
              src="/pexels-darina-belonogova-7959670%201-1300w.png"
              className="home-image47"
            />
            <img
              alt="image"
              src="/pexels-darina-belonogova-7964869%201-500w.png"
              className="home-image48"
            />
          </div>
          <div className="home-column10">
            <div className="home-yellow1"></div>
            <img
              alt="image"
              src="/pexels-darina-belonogova-8373875%203-1300w.png"
              className="home-image49"
            />
            <div className="home-row7">
              <img
                alt="image"
                src="/group%202106.svg"
                className="home-image50"
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="home-newsletter">
        <div className="home-header6">
          <h2 className="home-text67">Subscribe to our newsletter</h2>
          <p className="home-text68">
            Sign up for our newsletter to get the latest news!
          </p>
        </div>
        <div className="home-content6">
          <div className="home-email">
            <input
              type="text"
              placeholder="Enter your email"
              className="home-textinput input"
            />
            <div className="home-submit">
              <img alt="image" src="/frame.svg" className="home-image51" />
            </div>
          </div>
          <div className="home-notice">
            <span className="home-notice1">
              By subscribing occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum
            </span>
          </div>
        </div>
      </section> */}
      <Footer text={t('footer.intro')}></Footer>
      {/* <div>
        <div className="home-container6">
          <Script
            html={`<style>
    .partner-container:hover .partner-image{
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(101%) contrast(102%);
    }
</style>`}
          ></Script>
        </div>
      </div> */}
    </div>
  )
}

export default Home
