import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/navbar'

import './privacy.css'

const Privacy = (props) => {
  return (
    <>
      <NavBar></NavBar>
      <div className="terms-privacy-container">
        <div className="terms-privacy-hero">
          <div className="terms-privacy-content">
            <Helmet>
              <title>隱私政策</title>
            </Helmet>
            <h1>隱私政策</h1>
            <p>CommerBiz（以下稱為“我們”或“本公司”）非常重視您的隱私。當您使用我們的電商平台時，我們會收集、使用和分享您的個人信息。<br></br>此隱私政策旨在幫助您了解我們如何處理您的個人數據，以及您在這方面的權利。</p>

            <h2>我們收集的信息</h2>
            <p>當您在我們的平台上註冊帳戶、下訂單或與我們聯繫時，我們可能會收集以下個人信息：</p>
            <ul>
              <li>姓名</li>
              <li>聯繫信息（如電子郵件地址、電話號碼）</li>
              <li>付款信息（如信用卡號、賬單地址）</li>
              <li>運送信息（如收貨地址）</li>
            </ul>

            <p>當您訪問我們的網站時，我們可能會自動收集以下信息：</p>
            <ul>
              <li>IP地址</li>
              <li>瀏覽器類型和版本</li>
              <li>操作系統</li>
              <li>瀏覽行為數據（如瀏覽的頁面、點擊的鏈接）</li>
            </ul>

            <h2>我們如何使用您的信息</h2>
            <p>我們收集的個人信息將用於以下目的：</p>
            <ul>
              <li>處理和管理您的訂單</li>
              <li>改善我們的服務和用戶體驗</li>
              <li>與您溝通，回應您的查詢和反饋</li>
              <li>發送營銷材料和促銷信息（如您選擇接收）</li>
              <li>履行法律義務</li>
            </ul>

            <h2>我們如何分享您的信息</h2>
            <p>我們不會向第三方出售或出租您的個人信息。我們僅在以下情況下共享您的信息：</p>
            <ul>
              <li>與我們的服務提供商（如支付處理商、物流公司）共享，以完成您的訂單</li>
              <li>法律要求時，如需遵守法庭命令或其他法律程序</li>
              <li>保護我們的權利和財產，或確保用戶和公眾的安全</li>
            </ul>

            <h2>您的權利</h2>
            <ul>
              <li>訪問我們持有的關於您的個人信息</li>
              <li>要求更正不准確或不完整的個人信息</li>
              <li>要求刪除您的個人信息（在某些情況下）</li>
              <li>反對或限制我們處理您的個人信息</li>
              <li>要求將您的個人信息轉移給您或第三方（數據可攜權）</li>
            </ul>

            <h2>資料安全</h2>
            <p>我們採取適當的技術和組織措施，保護您的個人信息免遭未經授權的訪問、使用或洩露。</p>

            <h2>Cookie 和類似技術</h2>
            <p>我們使用cookie和類似技術來增強您的瀏覽體驗。您可以通過瀏覽器設置管理cookie的使用。</p>

            <h2>隱私政策的變更</h2>
            <p>我們可能會不時更新此隱私政策。任何變更將在我們的網站上發布，並且在發布後即生效。</p>

            <h2>聯繫我們</h2>
            <p>如有任何關於隱私政策的問題或需求，請通過以下方式聯繫我們：</p>
            <ul>
              <li>電子郵件：<a href="mailto:theplutusteam@gmail.com">theplutusteam@gmail.com</a></li>
            </ul>
          </div>
        </div>
      </div >
      <div style={{ padding: 10 }}>
      </div>
    </>

  )
}

export default Privacy
